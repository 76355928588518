import "@/css/squad.css";
import React, { useState, useEffect } from "react";
import Layout from "@/components/layout";
import Footer from "@/components/footer";
import HeaderOneTop from "@/components/header-one-top";
import HeaderOne from "@/components/header-one";
import SearchContextProvider from "@/context/search-context";
import MenuContextProvider from "@/context/menu-context";
import SliderTop from "@/components/squad/slider-top";
import SquadShowcase from "@/components/squad/squad-showcase";
import api from "@/axios";
import { useQueryParam, NumberParam } from "use-query-params";

const Squad = () => {
  const [paramId, setParamId] = useQueryParam("id", NumberParam);
  const [apiData, setData] = useState(null);
  const [apiDataFull, setDataFull] = useState(null);
  const [uniqueTeams, setUniqueTeams] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await api.get(`/players/o/get`, {
          params: { actualSeasonPlayers: true },
        });
        setData(
          paramId
            ? response.data.filter(v => v.team.id == paramId)
            : response.data.filter(v => v.team.id == 2)
        );
        setDataFull(response.data);

        const teamsArr = [];
        response.data.filter(v => {
          teamsArr.push(v.team);
        });
        const uniqueTeamsFinal = teamsArr.filter(
          (thing, index, self) =>
            index === self.findIndex(t => t.id === thing.id)
        );
        setUniqueTeams(uniqueTeamsFinal);

        setError(null);
      } catch (err) {
        setError(err.message);
        setData(null);
      } finally {
        setLoading(false);
      }
    };
    getData();
  }, [paramId]);

  const onChangeSelectTeam = e => {
    const filteredTeam = apiDataFull.filter(v => v.team.id == e);
    setData(filteredTeam);
  };

  return (
    <MenuContextProvider>
      <SearchContextProvider>
        <Layout PageTitle="Szatnia">
          <HeaderOneTop />
          <HeaderOne />
          <SliderTop
            error={error ? error : null}
            loading={loading ? true : false}
            apiData={apiData ? apiData : null}
            teamsSelect={uniqueTeams ? uniqueTeams : null}
            select={onChangeSelectTeam}
            paramId={paramId}
          />
          <SquadShowcase
            error={error ? error : null}
            loading={loading ? true : false}
            apiData={apiData ? apiData : null}
          />
          <Footer />
        </Layout>
      </SearchContextProvider>
    </MenuContextProvider>
  );
};

export default Squad;
