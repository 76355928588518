import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Col, Container, Row } from "react-bootstrap";
import { EffectCards, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/effect-cards";
import { Link } from "gatsby";
import { Select } from "antd";
import jagaLogo from "@/images/v-logo.png";
import sliderTop01 from "@/images/bg/squad/dacdedsde-2.jpg";

const { Option } = Select;

const SliderTop = ({
  error,
  loading,
  apiData,
  teamsSelect,
  select,
  paramId,
}) => {
  const mainSlideOptions = {
    slidesPerView: 1,
    loop: false,
    effect: "fade",
    navigation: {
      nextEl: "#main-slider-next",
      prevEl: "#main-slider-prev",
    },
    allowTouchMove: false,
  };
  return (
    <section className="main-slider slider-subpages">
      <Swiper {...mainSlideOptions}>
        <SwiperSlide style={{ height: 500 }}>
          <div
            className="image-layer squad-image"
            style={{
              backgroundImage: `url('${sliderTop01}')`,
            }}
          ></div>
          <Container className="mb-3">
            <Row className="justify-content-center">
              {!loading && (
                <Select
                  className="timetable-select text-center w-auto"
                  defaultValue={paramId || 2}
                  onChange={select}
                >
                  {teamsSelect &&
                    teamsSelect.map((teamData, index) => (
                      <Option value={teamData.id} key={index}>
                        {teamData.fullname}
                      </Option>
                    ))}
                </Select>
              )}
            </Row>
          </Container>
        </SwiperSlide>
      </Swiper>
      {/* SVG blur filter */}
      <svg
        className="svg-filter"
        width="0"
        height="0"
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
      >
        <defs>
          <filter id="blurfilter">
            <feGaussianBlur in="SourceGraphic" stdDeviation="8" result="blur" />
            <feColorMatrix
              in="blur"
              mode="matrix"
              values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9"
              result="blurfilter"
            />
            <feComposite in="SourceGraphic" in2="blurfilter" operator="atop" />
          </filter>
        </defs>
      </svg>
    </section>
  );
};

export default SliderTop;
