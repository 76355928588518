import React from "react";
import { Link } from "gatsby";

const SquadCard = ({ data }) => {
  const {
    posId,
    role,
    processedName,
    firstname,
    lastname,
    shirtNumber,
    gridImage,
  } = data;
  return (
    <Link to={`/szatnia/${processedName}`}>
      <div className="singleTM card-container squad-card-container text-end">
        <div
          className="tm_img squad-showcase"
          style={{
            backgroundImage: `url('${
              data.gridImage
                ? `${process.env.GATSBY_API_BASE_URL}/${data.gridImage.path}`
                : ""
            }')`,
          }}
        >
          <div className="tm_overlay"></div>
          <div
            className={`squad-showcase-number ${
              posId == 4 && "squad-card-staff"
            }`}
          >
            {posId == 4 ? role : shirtNumber}
          </div>
          <div className="detail_TM">
            <h5>{firstname}</h5>
            <h5>{lastname}</h5>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default SquadCard;
