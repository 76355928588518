import React, { useState, useEffect } from "react";
import { Col, Container, Row, Nav, NavDropdown } from "react-bootstrap";
import SquadCard from "@/components/cards/squad";
import Tab from "react-bootstrap/Tab";

const SquadShowcase = ({ error, loading, apiData }) => {
  const positionList = [
    {
      id: 0,
      name: "Bramkarze",
    },
    {
      id: 1,
      name: "Obrońcy",
    },
    {
      id: 2,
      name: "Pomocnicy",
    },
    {
      id: 3,
      name: "Napastnicy",
    },
    {
      id: 4,
      name: "Sztab",
    },
  ];
  const [selectedPositionData, setSelectedPositionData] = useState(apiData);
  function handleSelect(e) {
    setSelectedPositionData(apiData.filter(v => v.posId == e));
  }

  return (
    <>
      <Tab.Container defaultActiveKey="all" onSelect={e => handleSelect(e)}>
        <Container className="px-5 text-center squad-showcase-tab-container">
          <Row>
            <Col xl={12} className="d-none d-xl-block" style={{ zIndex: 1 }}>
              <Nav className="justify-content-center">
                <Nav.Item className="mx-2">
                  <Nav.Link eventKey="all">WSZYSTKIE</Nav.Link>
                </Nav.Item>
                {!loading &&
                  positionList.map((data, index) => (
                    <Nav.Item key={index} className="mx-2">
                      <Nav.Link eventKey={data.id}>{data.name}</Nav.Link>
                    </Nav.Item>
                  ))}
              </Nav>
            </Col>
            <Col
              className="d-block d-xl-none col-auto mx-auto"
              style={{ zIndex: 1 }}
            >
              <NavDropdown title="Pozycja" id="nav-dropdown">
                <NavDropdown.Item eventKey="all">Wszystkie</NavDropdown.Item>
                {!loading &&
                  positionList.map((data, index) => (
                    <NavDropdown.Item key={index} eventKey={data.id}>
                      {data.name}
                    </NavDropdown.Item>
                  ))}
              </NavDropdown>
            </Col>
          </Row>
        </Container>
        <Container className="squad-tab-content-container">
          <Tab.Content>
            <Tab.Pane eventKey="all">
              <Container className="animated fadeInUp">
                <Row className="mb-4">
                  <h4 className="text-uppercase fw-bold ps-5 ms-5">
                    Bramkarze
                  </h4>
                </Row>
                <Row className="mt-4">
                  {loading && <span className="loader"></span>}
                  {error && (
                    <div className="text-dark">{`Wystąpił problem z wczytaniem zawodników - ${error}`}</div>
                  )}
                  {!loading &&
                    apiData &&
                    apiData.map(
                      (post, index) =>
                        post.posId == 0 && (
                          <Col
                            lg={3}
                            md={4}
                            sm={6}
                            className="col-12 mb-5"
                            key={index}
                          >
                            <SquadCard data={post} />
                          </Col>
                        )
                    )}
                </Row>
              </Container>
              <Container className="mt-2">
                <Row className="mb-4">
                  <h4 className="text-uppercase fw-bold ps-5 ms-5">Obrońcy</h4>
                </Row>
                <Row className="mt-4">
                  {loading && <span className="loader"></span>}
                  {error && (
                    <div className="text-dark">{`Wystąpił problem z wczytaniem zawodników - ${error}`}</div>
                  )}
                  {!loading &&
                    apiData &&
                    apiData.map(
                      (post, index) =>
                        post.posId == 1 && (
                          <Col
                            lg={3}
                            md={4}
                            sm={6}
                            className="col-12 mb-5"
                            key={index}
                          >
                            <SquadCard data={post} />
                          </Col>
                        )
                    )}
                </Row>
              </Container>
              <Container className="mt-2">
                <Row className="mb-4">
                  <h4 className="text-uppercase fw-bold ps-5 ms-5">
                    Pomocnicy
                  </h4>
                </Row>
                <Row className="mt-4">
                  {loading && <span className="loader"></span>}
                  {error && (
                    <div className="text-dark">{`Wystąpił problem z wczytaniem zawodników - ${error}`}</div>
                  )}
                  {!loading &&
                    apiData &&
                    apiData.map(
                      (post, index) =>
                        post.posId == 2 && (
                          <Col
                            lg={3}
                            md={4}
                            sm={6}
                            className="col-12 mb-5"
                            key={index}
                          >
                            <SquadCard data={post} />
                          </Col>
                        )
                    )}
                </Row>
              </Container>
              <Container className="mt-2">
                <Row className="mb-4">
                  <h4 className="text-uppercase fw-bold ps-5 ms-5">
                    Napastnicy
                  </h4>
                </Row>
                <Row className="mt-4">
                  {loading && <span className="loader"></span>}
                  {error && (
                    <div className="text-dark">{`Wystąpił problem z wczytaniem zawodników - ${error}`}</div>
                  )}
                  {!loading &&
                    apiData &&
                    apiData.map(
                      (post, index) =>
                        post.posId == 3 && (
                          <Col
                            lg={3}
                            md={4}
                            sm={6}
                            className="col-12 mb-5"
                            key={index}
                          >
                            <SquadCard data={post} />
                          </Col>
                        )
                    )}
                </Row>
              </Container>
              <Container className="mt-2">
                <Row className="mb-4">
                  <h4 className="text-uppercase fw-bold ps-5 ms-5">Sztab</h4>
                </Row>
                <Row className="mt-4">
                  {loading && <span className="loader"></span>}
                  {error && (
                    <div className="text-dark">{`Wystąpił problem z wczytaniem zawodników - ${error}`}</div>
                  )}
                  {!loading &&
                    apiData &&
                    apiData.map(
                      (post, index) =>
                        post.posId == 4 && (
                          <Col
                            lg={3}
                            md={4}
                            sm={6}
                            className="col-12 mb-5"
                            key={index}
                          >
                            <SquadCard data={post} />
                          </Col>
                        )
                    )}
                </Row>
              </Container>
            </Tab.Pane>
            {!loading &&
              positionList.map((data, index) => (
                <Tab.Pane eventKey={data.id} key={index}>
                  <Row className="animated fadeInUp px-5">
                    {!loading &&
                      selectedPositionData &&
                      selectedPositionData.map((pos, index) => (
                        <Col
                          lg={3}
                          md={4}
                          sm={6}
                          className="col-12 mb-5"
                          key={index}
                        >
                          <SquadCard data={pos} />
                        </Col>
                      ))}
                  </Row>
                </Tab.Pane>
              ))}
          </Tab.Content>
        </Container>
      </Tab.Container>
    </>
  );
};

export default SquadShowcase;
